const env = {
  // baseUrl: process.env.BACKEND_URL || "https://machrezy.macleods.in/",
  baseUrl: process.env.BACKEND_URL || "https://macloeds.yoctotta.com/test",
  // baseUrl: process.env.BACKEND_URL || "http://10.1.1.110:3330/",
  // baseUrl: process.env.BACKEND_URL || "http://10.1.1.110:3330/",
  // baseUrl: process.env.BACKEND_URL || "http://internal.yoctotta.com:3330/",
  // baseUrl: process.env.BACKEND_URL || "http://10.0.21.61:3330/",
  // baseUrl: process.env.BACKEND_URL || "http://192.168.29.107:3330/",
  // baseUrl: process.env.BACKEND_URL || "/",
};
export default env;
